
import { nFormatter } from "@/mixins/number";
import { DashboardStatsState } from "@/store/modules/stats/stats.types";
import VueApexCharts from "vue-apexcharts";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Stats = namespace("Stats");

@Component({
  components: {
    VueApexCharts,
  },
})
export default class OrdersChart extends Vue {
  @Stats.State("dashboardStats")
  public dashboardStats!: DashboardStatsState;

  get options(): unknown {
    return {
      chart: {
        id: "vuechart-example",
      },
      stroke: {
        width: [0, 4],
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      colors: this.dashboardStats.order.stats.colors,
      labels: this.dashboardStats.order.stats.labels,
      xaxis: {
        type: "datetime",
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: this.salesColor,
          },
          labels: {
            style: {
              colors: this.salesColor,
            },
            formatter: (value: number) => {
              return nFormatter(value, 1);
            },
          },
          title: {
            text: "Sales",
            style: {
              color: this.salesColor,
            },
          },
        },
        {
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: this.ordersColor,
          },
          labels: {
            style: {
              colors: this.ordersColor,
            },
            formatter: (value: number) => {
              return nFormatter(value, 1);
            },
          },
          title: {
            text: "Orders",
            style: {
              color: this.ordersColor,
            },
          },
        },
      ],
    };
  }

  get series(): { name: string; type: string; data: number[] }[] {
    return [
      {
        name: "Sales",
        type: "column",
        data: this.dashboardStats.order.stats.sales,
      },
      {
        name: "Orders",
        type: "line",
        data: this.dashboardStats.order.stats.orders,
      },
    ];
  }

  get salesColor(): string {
    if (this.dashboardStats.order.stats.colors.length > 0) {
      return this.dashboardStats.order.stats.colors[0];
    }
    return "#000000";
  }

  get ordersColor(): string {
    if (this.dashboardStats.order.stats.colors.length > 1) {
      return this.dashboardStats.order.stats.colors[1];
    }
    return "#000000";
  }
}
